import {NgModule} from '@angular/core';
import {EzPlusDirective} from './ez-plus.directive';

@NgModule({
  declarations: [
    EzPlusDirective
  ],
  imports: [],
  exports: [
    EzPlusDirective
  ]
})
export class EzPlusModule {
}
